import React, { Component, } from 'react';
import GoogleAnalytics from 'react-ga';

let GOOGLE_ANALYTICS_ID = 'UA-0000000-0';

// To prevent runs in CI/CD env
if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID && process.env.REACT_APP_ENVIRONMENT) {
  GOOGLE_ANALYTICS_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;

  if (process.env.REACT_APP_ENVIRONMENT === 'staging' && process.env.REACT_APP_GOOGLE_ANALYTICS_ID_STAGING) {
    GOOGLE_ANALYTICS_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID_STAGING;
  }

  GoogleAnalytics.initialize(GOOGLE_ANALYTICS_ID);
}

const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = page => {
    GoogleAnalytics.set({
      page,
      ...options,
    });
    GoogleAnalytics.pageview(page);
  };

  // eslint-disable-next-line
  const HOC = class extends Component {
    componentDidMount () {
      // eslint-disable-next-line
      const page = this.props.location.pathname + this.props.location.search;
      trackPage(page);
    }

    componentDidUpdate (prevProps) {
      const currentPage =
        prevProps.location.pathname + prevProps.location.search;
      const nextPage =
        this.props.location.pathname + this.props.location.search;

      if (currentPage !== nextPage) {
        trackPage(nextPage);
      }
    }

    render () {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
};

export default withTracker;